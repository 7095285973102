<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 100%">
      <div class="title">统计分析</div>
      <div class="formDiv" style="width: 70vw">
        <el-form label-position="right" label-width="70px">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="选择:">
                <el-select
                  style="height: 40px"
                  v-model="value3"
                  @change="chengeType"
                  ><el-option label="月份" :value="0"></el-option
                  ><el-option label="日期" :value="1"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="月份:" v-if="value3 == 0">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="value2"
                  type="month"
                  @change="changeMonth"
                  placeholder="选择月"
                  format="YYYY-MM"
                  value-format="YYYY-MM"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="时间:" class="time_pick" v-if="value3 == 1">
                <el-date-picker
                  style="height: 40px; width: 100%"
                  v-model="FormData.orderTime"
                  type="daterange"
                  align="right"
                  range-separator=" - "
                  size="small"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  clearable
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="6">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div
                class="BtnSearch"
                style="margin-top: 5px"
                @click="searchBtn()"
              >
                查询
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="card statistics">
        <div>
          <router-link to="" style="color: #ff6060">
            <p class="s_title">
              营收总金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.count_money }}</p>
            <el-tooltip
              content="营收总金额 = 临时充电金额+钱包充值金额-钱包余额退费-临时充电退费"
              placement="bottom"
              effect="light"
            >
              <div
                style="width: 220px; height: 100px; margin-top: -100px"
              ></div>
            </el-tooltip>
          </router-link>
        </div>

        <div>
          <router-link
            style="color: #2ed2aa"
            :to="{
              path: '/order_manage/order_history',
              query: { zftype: '微信' },
            }"
          >
            <p class="s_title">
              临时充电金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.sum_money }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </router-link>
        </div>
        <div>
          <router-link style="color: #29a1ec" to="/revenue_manage/invest_detail">
            <p class="s_title">
              钱包充值金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.Balance_recharge }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </router-link>
        </div>

        <div>
          <router-link
            style="color: #6e39e8"
            to="/revenue_manage/refund_record"
          >
            <p class="s_title p_bottom">
              临时充电退费(元)
              <!-- <el-icon><Bottom /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.Return_of_order }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </router-link>
        </div>
        <div>
          <router-link
            style="color: #94bb22"
            to="/revenue_manage/refund_detail"
          >
            <p class="s_title">
              钱包余额退费(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.refund_money }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </router-link>
        </div>
        <div>
          <router-link
            style="color: #ffa300"
            :to="{
              path: '/order_manage/order_history',
              query: { zftype: '余额' },
            }"
          >
            <p class="s_title">
              钱包消费金额(元)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.wallet_pay }}</p>
            <!-- <p class="s_profress">+2%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </router-link>
        </div>
        <!-- <div>
          <router-link style="color: #ff6060" to="/revenue_manage/user_recharg">
            <p class="s_title p_bottom">
              钱包剩余金额(元)
            </p>
            <p class="s_water">{{ FormData.wallet_balance }}</p>
          </router-link>
        </div> -->
        <div>
          <router-link style="color: #6e39e8" to="/order_manage/order_history">
            <p class="s_title">
              用电量(度)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.electric_quantity }}</p>
            <!-- <p class="s_profress">+5%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </router-link>
        </div>

        <div>
          <router-link style="color: #ff6060" to="" @click="toOrder(2)">
            <p class="s_title">
              电动车订单数量(个)
              <!-- <el-icon><Top /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.type_id2 }}</p>
            <!-- <p class="s_profress">+0.1%</p> -->
            <!-- <el-progress :percentage="70" :show-text="false" /> -->
          </router-link>
        </div>
        <div>
          <router-link style="color: #29a1ec" to="/order_manage/order_history">
            <p class="s_title p_bottom">
              电动汽车订单数量(个)
              <!-- <el-icon><Bottom /></el-icon> -->
            </p>
            <p class="s_water">{{ FormData.type_id4 }}</p>
            <!-- <p class="s_profress">-0.1%</p> -->
            <!-- <el-progress :percentage="50" :show-text="false" /> -->
          </router-link>
        </div>
      </div>
    </div>
    <div class="" style="height: 100%; margin-top: 15px">
      <div class="title" style="margin-bottom: 10px">今日统计分析</div>

      <div class="card2">
        <div class="cards21 cad1">
          <div class="cardflex">
            <div>
              <p class="s_title1">今日营收总金额(元)</p>
              <p class="s_water1">{{ FormData1.count_money }}</p>
            </div>
          </div>
          <div class="cardflex martop">
            <div>
              <p class="s_title1">临时充电金额(元)</p>
              <p class="s_water1">{{ FormData1.sum_money }}</p>
            </div>
            <div>
              <p class="s_title1">钱包充值金额(元)</p>
              <p class="s_water1">{{ FormData1.Balance_recharge }}</p>
            </div>
          </div>
        </div>
        <div class="cards21 cad2">
          <div class="cardflex">
            <div>
              <p class="s_title1">钱包消费金额(元)</p>
              <p class="s_water1">{{ FormData1.wallet_pay }}</p>
            </div>
            <div>
              <p class="s_title1">钱包剩余金额(元)</p>
              <p class="s_water1">{{ FormData.wallet_balance }}</p>
            </div>
          </div>

          <div class="cardflex martop">
            <div>
              <p class="s_title1">临时充电退费(元)</p>
              <p class="s_water1">{{ FormData1.Return_of_order }}</p>
            </div>
            <div>
              <p class="s_title1">钱包余额退费(元)</p>
              <p class="s_water1">{{ FormData1.refund_money }}</p>
            </div>
            <!-- <div>
              <p class="s_title1">钱包剩余金额(元)</p>
              <p class="s_water1">{{ FormData1.wallet_balance }}</p>
            </div> -->
          </div>
        </div>
        <div class="cards21 cad3">
          <p class="s_title1">用电量(度)</p>
          <p class="s_water1">{{ FormData1.electric_quantity }}</p>
          <div class="cardflex martop">
            <div>
              <p class="s_title1">电动车订单数量(个)</p>
              <p class="s_water1">{{ FormData1.type_id2 }}</p>
            </div>
            <div>
              <p class="s_title1">电动汽车订单数量(个)</p>
              <p class="s_water1">{{ FormData1.type_id4 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottomDiv" style="height: 25vh; margin-top: 15px">
      <div class="title">快捷方式</div>
      <div class="card1">
        <div>
          <router-link to="/order_manage/order_history">
            <img src="../../assets/img/icon_4.png" alt="" />
            <p>订单记录</p>
          </router-link>
        </div>

        <div>
          <router-link to="/revenue_manage/order_refund">
            <img src="../../assets/img/icon_2.png" alt="" />
            <p>订单退费</p>
          </router-link>
        </div>
        <div>
          <router-link to="/revenue_manage/user_recharg">
            <img src="../../assets/img/icon_6.png" alt="" />
            <p>余额充值</p>
          </router-link>
        </div>
        <div>
          <router-link
            to="/revenue_manage/invest_detail"
            style="display: block"
          >
            <img src="../../assets/img/icon_7.png" alt="" />
            <p>余额退费</p>
          </router-link>
        </div>
        <div>
          <router-link to="/user_manage/search_man" style="display: block">
            <img src="../../assets/img/icon_12.png" alt="" />
            <p>用户管理</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs, ref } from "vue-demi";
import { operator_data, revenue_management } from "@/request/api";
import { useRouter } from 'vue-router'; // 引入 useRouter  


export default {
  name: "RevenueView",
  setup() {
    const router = useRouter();
    const data = reactive({
      value2: "",
      value3: 0,
      FormData: {
        orderTime: ["", ""],
      },
      FormData1: {
        orderTime: ["", ""],
      },
      //表单数据
      tableData: [],
      loading: false,
      isadmin: false,
      users: [],
      nowDateStar: "",
      nowDateStop: "",
    });
    const toOrder = (type_id) => {
      sessionStorage.setItem("type_id", type_id);
      router.push({
        path: "/order_manage/order_history",
      });
    };
    const searchBtn = () => {
      getList();
      getList1();
    };
    const formatDate = (date) => {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();

      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    };
    const handleEdit = () => {
      console.log("导出");
    };
    const handleSave = () => {
      console.log("打印");
    };

    const styles = `<style>
    .eltable{
      width:1000px;
    }
    </style>`;
    const chengeType = () => {
      data.FormData.orderTime = ["", ""];
    };
    const changeMonth = (value) => {
      console.log(value, "valuevalue");
      if (value != null && value != undefined && value != "") {
        let date = new Date(value);
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        let day = new Date(year, month, 0);
        data.FormData.orderTime[0] = year + "-" + month + "-01";
        data.FormData.orderTime[1] = year + "-" + month + "-" + day.getDate();
      } else {
        data.FormData.orderTime[0] = "";
        data.FormData.orderTime[1] = "";
      }
    };
    const getList = () => {
      let dataa;
      if (data.FormData.orderTime) {
        dataa = {
          operator_id: sessionStorage.getItem("userID"),
          start_execute_date: data.FormData.orderTime[0]
            ? data.FormData.orderTime[0]
            : "",
          stop_execute_date: data.FormData.orderTime[1]
            ? data.FormData.orderTime[1]
            : "",
          username: data.FormData.username,
        };
      } else if (
        data.FormData.orderTime == null ||
        data.FormData.orderTime == undefined
      ) {
        dataa = {
          operator_id: sessionStorage.getItem("userID"),
          username: data.FormData.username,
          start_execute_date: "",
          stop_execute_date: "",
        };
      }
      revenue_management(dataa).then((res) => {
        console.log(res, "sssssss");
        data.FormData.type_id2 = res.data.type_id2
          ? res.data.type_id2.achieve.toLocaleString()
          : 0;
        data.FormData.type_id4 = res.data.type_id4
          ? res.data.type_id4.achieve.toLocaleString()
          : 0;
        data.FormData.sum_money = Number(
          res.data.sum_money ? res.data.sum_money.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData.Balance_recharge = Number(
          res.data.Balance_recharge ? res.data.Balance_recharge.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData.Return_of_order = Number(
          res.data.Return_of_order ? res.data.Return_of_order.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData.refund_money = Number(
          res.data.refund_money ? res.data.refund_money.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // data.FormData.refill_card  = parseFloat( res.data.refill_card?res.data.refill_card.achieve:0).toFixed(2)
        data.FormData.count_money = Number(
          res.data.count_money ? res.data.count_money.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData.electric_quantity = Number(
          res.data.electric_quantity ? res.data.electric_quantity.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData.wallet_pay = Number(
          res.data.wallet_pay ? res.data.wallet_pay.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData.wallet_balance = Number(
          res.data.wallet_balance ? res.data.wallet_balance.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });
    };
    const getDateNow = () => {
      // 获取当前日期
      let currentDate = new Date();
      // 获取前一天
      let previousDay = new Date(currentDate);
      previousDay.setDate(currentDate.getDate());
      // 获取后一天
      let nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      data.nowDateStar = formatDate1(previousDay);
      data.nowDateStop = formatDate1(nextDay);
    };
    const formatDate1 = (data) => {
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      return year + "-" + month + "-" + date;
    };

    const getList1 = () => {
      getDateNow();
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        start_execute_date: data.nowDateStar,
        stop_execute_date: data.nowDateStop,
        username: data.FormData.username,
      };
      revenue_management(dataa).then((res) => {
        console.log(res, "sssssss");
        data.FormData1.type_id2 = res.data.type_id2
          ? res.data.type_id2.achieve.toLocaleString()
          : 0;
        data.FormData1.type_id4 = res.data.type_id4
          ? res.data.type_id4.achieve.toLocaleString()
          : 0;
        data.FormData1.sum_money = Number(
          res.data.sum_money ? res.data.sum_money.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData1.Balance_recharge = Number(
          res.data.Balance_recharge ? res.data.Balance_recharge.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData1.Return_of_order = Number(
          res.data.Return_of_order ? res.data.Return_of_order.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData1.refund_money = Number(
          res.data.refund_money ? res.data.refund_money.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // data.FormData.refill_card  = parseFloat( res.data.refill_card?res.data.refill_card.achieve:0).toFixed(2)
        data.FormData1.count_money = Number(
          res.data.count_money ? res.data.count_money.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData1.electric_quantity = Number(
          res.data.electric_quantity ? res.data.electric_quantity.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData1.wallet_pay = Number(
          res.data.wallet_pay ? res.data.wallet_pay.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        data.FormData1.wallet_balance = Number(
          res.data.wallet_balance ? res.data.wallet_balance.achieve : 0
        )
          .toFixed(2)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const zforder = () => {
      router.push({
        path: "/order_manage/order_history",
        query: {
          zftype: "zhif",
        },
      });
    };

    const yeorder = () => {
      router.push({
        path: "/order_manage/order_history",
        query: {
          zftype: "yue",
        },
      });
    };

    onMounted(() => {
      getList();
      // getTime();

      getList1();

      getUsername();
    });
    return {
      ...toRefs(data),
      searchBtn,
      handleEdit,
      handleSave,
      getList,
      getList1,
      // getTime,
      getUsername,
      formatDate,
      zforder,
      yeorder,
      toOrder,
      chengeType,
      changeMonth,
    };
  },
};
</script>
<style scoped>
.eltable {
  width: 90%;
  margin: 5px auto;
  height: 240px;
  overflow-y: auto;
}
.formDiv {
  width: 1000px;
  margin: 20px auto 0;
}
.bottomDiv {
  min-height: 240px;
}
.bottomDiv {
  min-height: 180px;
}
.card {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 4px;
  background-size: 100% 100%;
}
.card2 {
  margin-top: 20px;
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: start;

  flex-wrap: wrap;
  padding-bottom: 4px;
  /* background-size: 100% 100%; */
}
.cards21 {
  /* background-image: ; */
  margin-right: 20px;
  width: 28.9%;
  height: 120px;
  padding: 20px;
}

.cad1 {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
  background-size: 100% 100%;
}
.cad2 {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8;
  background-size: 100% 100%;
}
.cad3 {
  background-image: url("../../assets/img/six.png");
  color: #ff6060 !important;
  background-size: 100% 100%;
}
.cardflex {
  display: flex;
  justify-content: space-between;
}
.martop {
  margin-top: 20px;
}
.s_title1 {
  margin-top: 5px;
  font-size: 16px;
  /* color: #fff; */
  /* text-align: center; */
}
.s_water1 {
  line-height: 30px;
  font-size: 20px;
  margin-left: 10px;
  /* color: rgb(255, 138, 92); */
  /* color: #fff; */
  /* text-align: center; */
}
.card1 {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  flex-wrap: wrap;
}
.card1 p {
  color: black !important;
}
.s_title {
  margin-top: 5px;
  font-size: 18px;
  text-align: center;
}
.s_title .el-icon {
  float: right;
  color: red;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 30px;
  text-align: center;
  margin-top: 16px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.card > div {
  width: 16%;
  margin: 10px auto;
  background: #f0f2f5;
  /* border: 1px solid red; */
  padding: 10px 20px;
  border-radius: 5px;
  height: 100px;
  background-size: 100% 100%;
}
.card > div > .el-progress {
  width: 45%;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}
.card1 > div {
  width: 13%;
  margin: 20px auto;
  /* border: 1px solid red; */
  padding: 10px;
  /* height: 200px; */
}
.card1 p {
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  margin-top: 10px;
}
.card1 > div img {
  margin: 0 25%;
  width: 50%;
}
.btn {
  color: #fff;
  background-color: #03beb0;
  border-color: #03beb0;
  width: 55px;
  height: 28px;
  line-height: 28px;
  /* margin-left: -100px; */
}
.statistics > div {
  padding-top: 20px;
}
.statistics > div:nth-child(1) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060;
}
.statistics > div:nth-child(2) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
}
.statistics > div:nth-child(3) {
  background-image: url("../../assets/img/five.png");
  color: #29a1ec !important;
}
.statistics > div:nth-child(4) {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8;
}
.statistics > div:nth-child(5) {
  background-image: url("../../assets/img/two.png");
  color: #94bb22;
}
.statistics > div:nth-child(6) {
  background-image: url("../../assets/img/one.png");
  color: #ffa300;
}
/* .statistics > div:nth-child(7) {
  background-image: url("../../assets/img/six.png");
  color: #2ed2aa;
} */
.statistics > div:nth-child(7) {
  background-image: url("../../assets/img/three.png");
  color: #29a1ec !important;
}
.statistics > div:nth-child(8) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060 !important;
}
.statistics > div:nth-child(9) {
  background-image: url("../../assets/img/five.png");
  color: #ffa300 !important;
}

.statistics1 > div {
  width: 130px;
  background-color: #ccc;
  height: 60px;
}
.statistics1 p {
  font-size: 16px;
}
.statistics1 .s_water {
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  margin-top: 4px;
  /* font-weight: bold; */
}
.el-popper.is-customized {
  /* Set padding to ensure the height is 32px */
  padding: 6px 12px;
  background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}

.el-popper.is-customized .el-popper__arrow::before {
  background: linear-gradient(45deg, #b2e68d, #bce689);
  right: 0;
}
</style>
